import React from 'react'

import { Carousel, Col, Row } from 'react-bootstrap';
import '../About.css'
import HomeIcon from '@mui/icons-material/Home';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import FaxIcon from '@mui/icons-material/Fax';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LanguageIcon from '@mui/icons-material/Language';
import Aboutcompany from '../About/Aboutcompany';
import Aboutenquiry from '../About/Aboutenquiry';

import { Link } from 'react-router-dom';
import Footer from '../Includes/Footer';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function Contact() {
    const { t, i18n } = useTranslation();
    const email = 'info@omanigulffood.com';

    const email1 = 's.kumar@ omanigulffood.com';
    const email2 = 'chowdhury.n@omanigulffood.com';
    const email3 = 'baljinder.singh@omanigulffood.com';
    const email4 = 'm.jaboob@omanigulffood.com';
    const email5 = 'said@omanigulffood.com';


    const email6 = 'jose.sm@omanigulffood.com';
    const email7 = 'm.jaboob@omanigulffood.com';
    const email8 = 'midhun@omanigulffood.com';
    const email9 = 'sreeni.v@omanigulffood.com';

    const [showPopupmapmuscut, setShowPopupmapmuscut] = useState(false);
    const [showPopupmapdubai, setShowPopupmapdubai] = useState(false);
    const [showPopupmapjeddah, setShowPopupmapjeddah] = useState(false);
    const [showPopupmapriyad, setShowPopupmapriyad] = useState(false);
    const [showPopupmahead, setShowPopupmaphead] = useState(false);





    const handleClickmapmuscut = () => {
        setShowPopupmapmuscut(true);
        setShowPopupmapdubai(false);
        setShowPopupmapjeddah(false);
        setShowPopupmapriyad(false);
    };
    const handleClickmapdubai = () => {
        setShowPopupmapmuscut(false);
        setShowPopupmapdubai(true);
        setShowPopupmapjeddah(false);
        setShowPopupmapriyad(false);
    };
    const handleClickmapjeddah = () => {
        setShowPopupmapmuscut(false);
        setShowPopupmapdubai(false);
        setShowPopupmapjeddah(true);
        setShowPopupmapriyad(false);
    };
    const handleClickmapriyad = () => {
        setShowPopupmapmuscut(false);
        setShowPopupmapdubai(false);
        setShowPopupmapjeddah(false);
        setShowPopupmapriyad(true);
    };
    const handleClickmaphead = () => {
        setShowPopupmaphead(true);
        setShowPopupmapmuscut(false);
        setShowPopupmapdubai(false);
        setShowPopupmapjeddah(false);
        setShowPopupmapriyad(false);
    };



    return (
        <div>

            <div className="headertop" >
                <Carousel className="slider ">
                    <Carousel.Item>
                        <img className="d-block w-100  " src="/contact/contactnew.png" alt="ogfc-img" />

                        <div className="carousel-caption caption-left">
                            <div class="left-line"></div>
                            <h1 className='linedown' style={{ color: '#000', fontFamily: t('fontFamilytwo') }}>{t("CONTACT")}<br></br>{t("US")}

                            </h1>
                            <p className='linedown' style={{ color: '#000', fontWeight: "600", fontFamily: t('fontFamilyone') }}>{t("Delivering healthy and tasty dishes for every kitchen")}<br></br>{t("with our pure and fresh cooking oil for decades.")}</p>
                        </div>

                    </Carousel.Item>
                </Carousel>
            </div>

            <div className='contactaddress'>
                <img src="/contact/footeraddress.jpg" alt="ogfc-img" />
                <div class="contact-footer">
                    <Row className='mx-5 mt-5 mx-sm-0 '>
                        <div className='head-office' >
                            <h1 className='mb-4' style={{ fontFamily: t('fontFamilytwo') }}>{t("HEAD OFFICE")}</h1>
                            < p style={{ fontFamily: t('fontFamilyone') }}> <HomeIcon variant="Filled" sx={{ margin: '0px 5px', color: '#99978b' }} />{t("P.O.Box 43, P.C. 217, Raysut Industrial Estate, Salalah, Sultanate of Oman.")}</p>
                            < p style={{ fontFamily: t('fontFamilyone') }}> <AddIcCallIcon variant="Filled" sx={{ margin: '0px 5px', color: '#99978b' }} /> + 968 23219142, +968 23219372, +968 23219323</p>
                            <p style={{ fontFamily: t('fontFamilyone') }}><FaxIcon variant="Filled" sx={{ margin: '0px 5px', color: '#99978b' }} />+ 968 23219324</p>
                            <p style={{ fontFamily: t('fontFamilyone') }} className="email-hover">
                                <AlternateEmailIcon variant="Filled" sx={{ margin: '0px 5px', color: '#99978b' }} />
                                <Link style={{ textDecoration: 'none', fontFamily: t('fontFamilyone') }} to={`mailto:${email}`}>info@omanigulffood.com</Link>
                            </p>
                            <p style={{ fontFamily: t('fontFamilyone') }} className="email-hover"><LanguageIcon variant="Filled" sx={{ margin: '0px 5px', color: '#99978b' }} />www.omanigulffood.com</p>


                            <div className='our-branch-first head-popup my-3'>




                                <button style={{ fontFamily: t('fontFamilyone') }} onClick={handleClickmaphead}>
                                    {t("Map view")}</button>



                                {showPopupmahead && (
                                    <div className="boardpopupnon iframe-container">
                                        <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("HEAD OFFICE")}</h1>
                                        < iframe
                                            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3816.185368737779!2d53.98454181487061!3d16.965429888332757!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTbCsDU3JzU1LjYiTiA1M8KwNTknMTIuMiJF!5e0!3m2!1sen!2sin!4v1689571386711!5m2!1sen!2sin"
                                            width="600"
                                            height="450"
                                            margin="auto"
                                            className="auto-width-iframe"
                                            style={{ border: '0', margin: 'auto' }}
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"

                                        >
                                        </iframe>
                                        <br></br>

                                        <button style={{ fontFamily: t('fontFamilyone') }} onClick={() => setShowPopupmaphead(false)}>{t("Close")}</button></div>

                                )}

                            </div>

                        </div>
                    </Row>

                    <Row  className='mx-0 mx-sm-0 mx-md-5 mx-lg-5 mx-xl-5'>

                        {/* <Col><div className='head-manager'>
                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("P.S.KUMAR")}</h1>
                            < p style={{ fontFamily: t('fontFamilyone') }}>{t("Chief Executive Officer")}</p>
                            < a >
                                {t("Email-e")}  <Link className="email-hover" style={{ textDecoration: 'none', fontFamily: t('fontFamilyone') }} to={`mailto:${email1}`}>s.kumar@omanigulffood.com</Link> {t("Email")}
                            </a>
                        </div></Col> */}
                        {/* <Col><div className='head-manager'>
                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("NIRMALYA CHOWDHURY")}</h1>
                            < p style={{ fontFamily: t('fontFamilyone') }}>{t("Head - Marketing & Sales")}</p>
                            < a >
                                {t("Email-e")} <Link className="email-hover" style={{ textDecoration: 'none', fontFamily: t('fontFamilyone') }} to={`mailto:${email2}`}>chowdhury.n@omanigulffood.com</Link>{t("Email")}
                            </a>

                        </div></Col> */}
                        <Col><div className='head-manager'>
                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("BALJINDER SINGH")}</h1>
                            < p style={{ fontFamily: t('fontFamilyone') }} >{t("Chief Financial Officer")}</p>
                            < a >
                                {t("Email-e")} <Link className="email-hover" style={{ textDecoration: 'none', fontFamily: t('fontFamilyone') }} to={`mailto:${email3}`}>baljinder.singh@omanigulffood.com</Link>{t("Email")}

                            </a>

                        </div></Col>
                        <Col >
                            <div className='head-manager'>
                                <h1 style={{ fontFamily: t('fontFamilyone') }}  >{t("V.SREENIVASULU")}</h1>
                                <p style={{ fontFamily: t('fontFamilyone') }} >{t("Factory Head")}</p>
                                <a >
                                    {t("Email-e")}  <Link className="email-hover" style={{ textDecoration: 'none', fontFamily: t('fontFamilyone') }} to={`mailto:${email9}`}>sreeni.v@omanigulffood.com</Link>{t("Email")}

                                </a>

                            </div>
                        </Col>
                    </Row>
                    <Row className='mx-0 mx-sm-0 mx-md-5 mx-lg-5 mx-xl-5'>
                    
                        <Col >
                            <div className='head-manager'>
                                <h1 style={{ fontFamily: t('fontFamilyone') }} >{t("MOHAMMED AHMED JABOOB")}</h1>
                                < p style={{ fontFamily: t('fontFamilyone') }} >{t("Commercial Manager")}</p>
                                < a >
                                    {t("Email-e")}   <Link className="email-hover" style={{ textDecoration: 'none', fontFamily: t('fontFamilyone') }} to={`mailto:${email4}`}>m.jaboob@omanigulffood.com</Link>{t("Email")}

                                </a>

                            </div>
                        </Col>

                        <Col >
                            <div className='head-manager'>

                                <h1 style={{ fontFamily: t('fontFamilyone') }} >{t("SAID MOHAMMED AL MASHANI")}</h1>
                                < p style={{ fontFamily: t('fontFamilyone') }} >{t("Manager - Admin & Services")}</p>
                                < a >
                                    {t("Email-e")} <Link className="email-hover" style={{ textDecoration: 'none', fontFamily: t('fontFamilyone') }} to={`mailto:${email5}`}>said@omanigulffood.com</Link>{t("Email")}

                                </a>

                            </div>
                        </Col>


                    </Row>
                </div>
            </div >
            <div className="main-branch pt-2">

                <div className='head-office'>
                    <h1 style={{ fontFamily: t('fontFamilytwo') }} className='my-5'>{t("OUR BRANCHES")}</h1></div>
                < div className='our-branches' >




                    <Row className='mx-0 mx-sm-0 mx-md-5 mx-lg-5 mx-xl-5'>
                        <Col xs="12" md="12"
                            lg="6">
                            <div className='our-branch'>
                                <h1 style={{ fontFamily: t('fontFamilyone') }} >{t("MUSCAT OFFICE")}</h1>
                                <h6 style={{ fontFamily: t('fontFamilyone') }} >{t("JOSE SEBASTIAN")}</h6>
                                <p style={{ fontFamily: t('fontFamilyone') }} >{t("Sales Manager")}</p>
                                <ul style={{ textDecoration: 'none', fontFamily: t('fontFamilyone') }} >
                                    <div className='d-flex align-items-baseline'> <span className='addressspan'>{t("Address")}</span> :&nbsp; <li>{t("Horizon House - 4th Floor, Above 5 cm Show Room, Near Dubai Travels, N.Gobrah, Sultanate of Oman.")}</li></div>
                                    <div className='d-flex align-items-baseline'> <span className='addressspan'>{t("Mobile")} :&nbsp; </span>< li>  + 968 99282893</li></div>
                                    <div className='d-flex align-items-baseline'>   <span className='addressspan'>{t("Tel")} :&nbsp; </span>< li >  + 968 24492176</li></div>
                                    <div className='d-flex align-items-baseline'><span className='addressspan'>{t("Fax")} :&nbsp; </span> < li >  + 968 24492142</li></div>
                                    <div className='d-flex align-items-baseline'> <span className='addressspan'>{t("Branch Logistics Tel / Fax")} :&nbsp; </span>< li >  + 968 26893195</li></div>


                                    <div className='d-flex align-items-baseline'><span className='addressspan'>{t("Email-address")} :&nbsp; </span> < li >
                                        < Link className="email-hover" style={{ textDecoration: 'none' }} to={`mailto:${email6}`}>  jose.sm@omanigulffood.com</Link>
                                    </li></div>
                                </ul>
                                <button style={{ fontFamily: t('fontFamilyone') }} onClick={handleClickmapmuscut} >




                                    {t("Map view")}</button>

                                {showPopupmapmuscut && (
                                    <div className="boardpopupnon iframe-container">
                                        <h1 style={{ fontFamily: t('fontFamilyone') }} >{t("MUSCAT OFFICE")}</h1>
                                        < iframe
                                            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3656.3738694098297!2d58.40381300000001!3d23.590920999999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjPCsDM1JzI3LjMiTiA1OMKwMjQnMTMuNyJF!5e0!3m2!1sen!2sin!4v1688549146949!5m2!1sen!2sin"
                                            width="600"
                                            height="450"
                                            className="auto-width-iframe"
                                            style={{ border: '0' }}
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"

                                        >
                                        </iframe>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        <button style={{ fontFamily: t('fontFamilyone') }} onClick={() => setShowPopupmapmuscut(false)}>{t("Close")}</button>
                                    </div>
                                )}


                            </div>
                        </Col>

                        <Col xs="12" md="12" lg="6" >
                            <div className='our-branch '>
                                <h1 style={{ fontFamily: t('fontFamilyone') }} >{t("DUBAI OFFICE")}</h1>
                                < h6 style={{ fontFamily: t('fontFamilyone') }} >{t("ANZAL MOHAMED")}</h6>
                                < p style={{ fontFamily: t('fontFamilyone') }} >{t("Asst.Sales Manager")}</p>
                                < ul style={{ fontFamily: t('fontFamilyone') }} >



                                    <div className='d-flex align-items-baseline'><span className='addressspan'>{t("Address")}  :&nbsp;</span> <li>{t("P.O Box-182434, Warehouse # 114, Al Jadaf, Dubai, UAE.")}</li></div>

                                    <div className='d-flex align-items-baseline'><span className='addressspan'>{t("Mobile")} :&nbsp;</span>  < li >  + 971 43393614</li></div>
                                    <div className='d-flex align-items-baseline'><span className='addressspan'>{t("Tel")} :&nbsp;</span> <li>  + 971 555014874</li></div>
                                    <div className='d-flex align-items-baseline'><span className='addressspan'>{t("Fax")} :&nbsp;</span>   <li>  + 971 43393615</li></div>
                                    <div className='d-flex align-items-baseline'><span className='addressspan'>{t("Branch Logistics Tel / Fax")} :&nbsp;</span>   < li > + 971 26893195</li></div>
                                    <div className='d-flex align-items-baseline'><span className='addressspan'>{t("Email-address")} :&nbsp;</span>
                                        < li > < Link className="email-hover" style={{ textDecoration: 'none' }} to={`mailto:${email7}`}> anzal@omanigulffood.com</Link>
                                        </li></div>

                                    <div className='d-flex align-items-baseline'><span className='addressspan'>{t("ABU-DHABI-Tel")} :&nbsp;</span>  <li>+971 25503162</li></div>
                                    <div className='d-flex align-items-baseline'><span className='addressspan'>{t("AJMAN")} :&nbsp;</span>  < li >   {t("Area 1, Near Police Point Round About")}</li></div>
                                    <div className='d-flex align-items-baseline'><span className='addressspan'>{t("Tel")} :&nbsp;</span>< li >  + 971 67489664</li></div>
                                </ul>
                                <button style={{ fontFamily: t('fontFamilyone') }} onClick={handleClickmapdubai} >{t("Map view")}</button>

                                {showPopupmapdubai && (
                                    <div className="boardpopupnon iframe-container">



                                        <h1 style={{ fontFamily: t('fontFamilyone') }} >{t("DUBAI OFFICE")}</h1>
                                        <iframe
                                            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3609.7579874066887!2d55.333480615009854!3d25.21138278388959!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjXCsDEyJzQxLjAiTiA1NcKwMjAnMDguNCJF!5e0!3m2!1sen!2sin!4v1688551052136!5m2!1sen!2sin"
                                            width="600"
                                            height="450"
                                            className="auto-width-iframe"
                                            style={{ border: '0' }}
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"

                                        >
                                        </iframe>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        <button style={{ fontFamily: t('fontFamilyone') }} onClick={() => setShowPopupmapdubai(false)}>{t("Close")}</button>
                                    </div>
                                )}

                            </div>
                        </Col>
                    </Row>
                    <Row className='mx-0 mx-sm-0 mx-md-5 mx-lg-5 mx-xl-5'>
                        <Col xs="12" md="12" lg="6">
                            <div className='our-branch '>


                                <h1 style={{ fontFamily: t('fontFamilyone') }} >{t("SAUDI BRANCH - RIYADH")}</h1>
                                < h6 style={{ fontFamily: t('fontFamilyone') }} > {t("MOHAMMAD FAKHARULLAH")}</h6>
                                <p style={{ fontFamily: t('fontFamilyone') }} >{t("Area Sales Manager")}</p>
                                < ul style={{ fontFamily: t('fontFamilyone') }} >

                                    <div className='d-flex align-items-baseline'> <span className='addressspan'>{t("Address")}:&nbsp; </span>  <li>{t("Warehouse No 4, Al Masanih Area, Al Hair Road Ibin, Qassim, Riyadh, Kingdom of Saudi Arabia.")}
                                    </li></div>
                                    < div className='d-flex align-items-baseline'> <span className='addressspan'>{t("Mobile")} :&nbsp; </span> < li>   +966597332705</li></div>

                                    <div className='d-flex align-items-baseline'> <span className='addressspan'>{t("Email-address")} :&nbsp; </span> <li>< Link className="email-hover" style={{ textDecoration: 'none' }} to={`mailto:${email8}`}> m.fakharullah@omanigulffood.com</Link></li></div>




                                </ul>
                                <button style={{ fontFamily: t('fontFamilyone') }} onClick={handleClickmapriyad} >{t("Map view")}</button>

                                {showPopupmapriyad && (
                                    <div className="boardpopupnon iframe-container">




                                        <h1 style={{ fontFamily: t('fontFamilyone') }} >{t("SAUDI BRANCH - RIYADH")}</h1>


                                        < iframe
                                            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3628.7015900183233!2d46.742722199999996!3d24.5649722!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjTCsDMzJzUzLjkiTiA0NsKwNDQnMzMuOCJF!5e0!3m2!1sen!2sin!4v1688551790011!5m2!1sen!2sin"
                                            width="600"
                                            height="450"
                                            className="auto-width-iframe"
                                            style={{ border: '0' }}
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"

                                        >
                                        </iframe>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        <button style={{ fontFamily: t('fontFamilyone') }} onClick={() => setShowPopupmapriyad(false)}>{t("Close")}</button>
                                    </div>
                                )}
                            </div>
                        </Col>


                        <Col xs="12" md="12" lg="6">
                            <div className='our-branch '>
                                <h1 style={{ fontFamily: t('fontFamilyone') }} >{t("SAUDI BRANCH-JEDDAH")}</h1>
                                < h6 style={{ fontFamily: t('fontFamilyone') }} > {t("MIDHUN JOHN ISSAC")}</h6>
                                < p style={{ fontFamily: t('fontFamilyone') }} > {t("Asst.Sales Manager")}</p>
                                < ul style={{ fontFamily: t('fontFamilyone') }} >

                                    <div className='d-flex align-items-baseline'> <span className='addressspan'>{t("Address")}:&nbsp; </span>  <li>{t("Warehouse 86, Al Fouzan Wh, Near Souk Al Gahanam, Al Kumurah, Jeddah,Kingdom of Saudi Arabia")}
                                    </li></div>
                                    < div className='d-flex align-items-baseline'> <span className='addressspan'>{t("Mobile")} :&nbsp; </span> < li>   +966 597332549</li></div>
                                    < div className='d-flex align-items-baseline'> <span className='addressspan'>{t("Tel")} :&nbsp; </span> < li>   + 966 126110604</li></div>
                                    <div className='d-flex align-items-baseline'> <span className='addressspan'>{t("Email-address")} :&nbsp; </span> <li>< Link className="email-hover" style={{ textDecoration: 'none' }} to={`mailto:${email8}`}> midhun@omanigulffood.com</Link></li></div>


                                </ul>



                                <button style={{ fontFamily: t('fontFamilyone') }} onClick={handleClickmapjeddah} >{t("Map view")}</button>

                                {showPopupmapjeddah && (
                                    <div className="boardpopupnon iframe-container">



                                        <h1 style={{ fontFamily: t('fontFamilyone') }} >{t("SAUDI BRANCH-JEDDAH")}</h1>
                                        <iframe
                                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3715.1250980125133!2d39.216351599999996!3d21.384978399999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3c9c20f69ed03%3A0xf8a2b93c5952797f!2sAs%20Surooriyah%2C%20Jeddah%2022523%2C%20Saudi%20Arabia!5e0!3m2!1sen!2sin!4v1688551447092!5m2!1sen!2sin"
                                            width="600"
                                            height="450"
                                            className="auto-width-iframe"
                                            style={{ border: '0' }}
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"

                                        >
                                        </iframe>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        <button style={{ fontFamily: t('fontFamilyone') }} onClick={() => setShowPopupmapjeddah(false)}>{t("Close")}</button>
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>
                </div >


            </div >
            <div>
                <Aboutcompany />
            </div>
            <div>
                <Aboutenquiry />
            </div>
            <Footer />
        </div >



    )
}

export default Contact